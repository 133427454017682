import React, {memo} from 'react';
import {Header} from "../components/Header/Header";
import {Footer} from "../components/Footer/Footer";
import {HTMLHelmet} from "../components/HTMLHelmet";
import {useConstants} from "../utils/constants";

const ApplicationLayout = ({children, location}) => {
    let pathname = `${location.pathname}${location.hash}`;
    const {meetingUsUrl} = useConstants();

    // remove all / characters at the end but not for pathname is '/'
    if (pathname.length > 1)
        pathname = pathname.replace(/\/+$/, ''); //$ marks the end of a string, \/~+$ means: all / characters at the end of a string

    return (
        <>
            <HTMLHelmet
                robots='all'
                description="Leansoft is a lean software development company in Danang, a fantastic city in Vietnam. Let's start with your business idea."
                keywords='lean soft, lean software development, bespoke developer, vietnam outsourcing, ireland outsourcing, flexible software, mvp software'
            />
            <Header pathname={pathname} contactUsUrl={meetingUsUrl}/>
            <main>
                {children}
            </main>
            <Footer contactUsUrl={meetingUsUrl}/>
        </>
    );
};

export default memo(ApplicationLayout);
