import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Link} from "gatsby";
import {IoIosMenu} from "react-icons/io";
import {MENU_HEADER} from "./Constant";
import {HeaderMobile} from "./HeaderMobile";
import {HTMLHelmet} from "../HTMLHelmet";
import {ButtonContactUs} from "../Button";
import classNames from "classnames";
import debounce from "lodash/debounce"; // use require('lodash/debounce') to reduce js size

const headerAutoFixed = false; // true - header auto fixed on top when scroll down; false - header no fixed, it as a default component in page

export const Header = ({pathname, contactUsUrl}) => {
    const [currentPathname, setCurrentPathname] = useState(pathname);
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

    // Update currentPathname after pathname change
    useLayoutEffect(() => {
        setCurrentPathname(pathname);
    }, [pathname]);

    // Auto set sticky for header when scroll change
    useEffect(() => {
        if (headerAutoFixed) {
            //
            const checkScrollForShowHeaderSticky = () => {
                setIsHeaderSticky(window.scrollY > 46); // space between header and image
            };
            // after refresh
            checkScrollForShowHeaderSticky();
            //
            window.addEventListener("scroll", checkScrollForShowHeaderSticky);
            // Remove event listener on cleanup
            return () => window.removeEventListener("scroll", checkScrollForShowHeaderSticky);
        }
    }, []);

    // Auto change menu item activated when scroll change
    useLayoutEffect(() => {
        let sections = document.querySelectorAll('section'); // ['home', 'services', 'team', 'projects']

        const checkSectionActivated = debounce(() => {
            sections.forEach(section => {
                let top = window.scrollY + 10;
                let offset = section.offsetTop;
                let height = section.offsetHeight;
                let id = section.getAttribute('id');

                if (top >= offset && top < offset + height) {
                    setCurrentPathname(MENU_HEADER.find(([, , sectionId]) => sectionId === id)?.[1]);
                }
            })
        }, 30);

        window.addEventListener("scroll", checkSectionActivated);
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", checkSectionActivated);
    }, [pathname]);

    const classHeader = classNames(
        'w-full left-0 right-0 top-0 z-30',
        {
            'bg-transparent': !isHeaderSticky,
            'bg-white dark:bg-gray-900 dark:bg-gradient-to-bl dark:from-primary-300/50 dark:to-primary-200/1 border-b-[1px] border-gray-900/10 dark:border-0': isHeaderSticky,
            'blur-sm': isShowMobileMenu,
            'fixed': headerAutoFixed,
            'absolute': !headerAutoFixed,
        }
    );

    const openMobileMenu = () => {
        setIsShowMobileMenu(true);
        document.body.style.overflow = "hidden";
    };

    const closeMobileMenu = () => {
        setIsShowMobileMenu(false);
        document.body.style.overflow = "scroll";
    };

    const clickMenuItem = (value) => {
        setCurrentPathname(value);
    };

    const clickMobileMenuItem = (value) => {
        closeMobileMenu();
        setCurrentPathname(value);
    };

    // Get title of website
    const getTitle = () => {
        const item = MENU_HEADER.find(([, path]) => path === currentPathname);
        return (item?.[3] === undefined ? item?.[0] : item?.[3]) || '';
    };

    return (
        <>
            <HTMLHelmet title={getTitle()}/>
            <header
                className={classHeader}>
                <div className={
                    `container mx-auto flex flex-row justify-between items-center duration-500 px-4
                    ${isHeaderSticky ? 'h-[70px]' : 'h-[100px]'}`
                }>
                    <Link to={'/'}>
                        <div className='logo dark:logo-white h-[60px] w-[67px]'/>
                    </Link>

                    <div className='hidden md:flex flex-1 justify-end mx-6 text-heading dark:text-heading-dark'>
                        {MENU_HEADER.map(([title, url], index) => <MenuItem onClick={clickMenuItem} key={index}
                                                                            url={url} title={title}
                                                                            active={url === currentPathname}/>)}
                    </div>

                    <ButtonContactUs url={contactUsUrl}/>

                    <button onClick={() => openMobileMenu()}
                            className={`flex items-center justify-center h-[50px] w-[50px] mr-[-10px] md:hidden outline-none`}>
                        <IoIosMenu style={{fontSize: 30}}/>
                    </button>
                </div>
            </header>

            <HeaderMobile isShowMenu={isShowMobileMenu} close={closeMobileMenu} onClick={clickMobileMenuItem}
                          currentPathname={currentPathname} contactUsUrl={contactUsUrl}/>
        </>
    );
};

const MenuItem = ({title, url, onClick, active = false}) => (
    <Link to={url} onClick={() => onClick(url)}
          className={`${active ? 'text-title dark:text-title-dark border-b-[2px] border-title' : ''} px-6 py-3 hover:text-title dark:hover:text-title-dark`}>
        {title}
    </Link>
);

