import React from 'react';

export const ButtonContactUs = ({url}) => <a
    href={url}
    target='_blank' rel="noreferrer"
    className='text-title dark:text-title-dark hover:shadow-title/40 dark:hover:shadow-title-dark/40 md:block hidden px-6 py-2 ring-title dark:ring-title-dark ring-[2px] hover:text-white hover:bg-title/80 dark:hover:bg-title-dark/80 hover:ring-title/80 dark:hover:ring-title-dark/80 hover:shadow-lg rounded-full duration-300'
>Contact us</a>;

export const ButtonContactUsMobile = ({url}) => <a
    href={url}
    target='_blank' rel="noreferrer"
    className='block text-white m-6 text-center px-6 py-2.5 bg-primary rounded-full'
>Contact us</a>;

//Has a error with Android phone (A2 Lite): if remove button then show link on two rows
export const ButtonGetStarted = ({url}) => <a
    href={url}
    target='_blank' rel="noreferrer"
>
    <button className='text-white px-6 py-2 bg-primary dark:bg-primary-dark hover:bg-primary/80 dark:hover:bg-primary-dark/80 shadow-lg shadow-primary/40 rounded-full duration-300'>Get Started</button>
</a>;
