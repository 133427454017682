import React from 'react';
import "./src/styles/global.css"
import Layout from "./src/layouts/ApplicationLayout";

export function wrapPageElement({element, props}) {
    return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({element}){
    return <>{element}</>
}
