import * as React from "react";
import {Helmet} from "react-helmet"

const default_keywords = 'lean soft, lean software development, bespoke developer, vietnam outsourcing, ireland outsourcing, flexible software, mvp software';
const default_robots = 'all';

export function HTMLHelmet({title, robots, description, keywords}) {
    return (
        <Helmet
            defaultTitle="Leansoft - A lean software development company in Danang, Vietnam"
            titleTemplate="%s - Leansoft"
        >
            <meta charSet="utf-8"/>
            <meta name="robots" content={robots || default_robots}/>
            <meta name="keywords" content={keywords||default_keywords}/>
            {title ? <title>{title}</title> : null}
            {description ? <meta name="description" content={description}/> : null}
        </Helmet>
    );
}
