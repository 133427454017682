import React from 'react';
import {Link} from "gatsby";
import {MENU_HEADER} from "./Constant";
import {IoIosClose} from "react-icons/io";
import {ButtonContactUsMobile} from "../Button";

export const HeaderMobile = ({close, currentPathname, onClick, isShowMenu, contactUsUrl}) => {
    return (
        <>
            {isShowMenu ? <div aria-hidden="true" onClick={() => close()} className='fixed bg-transparent w-full h-full z-20 bg-black/20 backdrop-blur-sm'/> : null}
            <header
                className={`overflow-auto fixed w-[260px] bg-white dark:bg-gray-900 dark:bg-gradient-to-bl dark:from-primary-300/50 dark:to-primary-200/1 top-0 right-0 bottom-0 z-30 duration-500 ${isShowMenu ? 'opacity-100 translate-x-0' : 'translate-x-full'}`}
            >
                <div className='flex justify-between items-center px-6 py-4 border-b-[1px] border-gray-900/10 dark:border-gray-100/30'>
                    <Link to={'/'} onClick={() => onClick('/')}>
                        <div className='logo-sm dark:logo-white-sm h-[40px] w-[38px]'/>
                    </Link>

                    <button onClick={() => close()}>
                        <IoIosClose style={{fontSize: 40,}} className=''/>
                    </button>
                </div>
                <div className='px-6 py-4 border-b-[1px] border-gray-900/10 dark:border-gray-100/30 font-medium'>
                    {MENU_HEADER.map(([title, url], index) => <MobileMenuItem onClick={onClick} key={index} url={url} title={title} active={url === currentPathname}/>)}
                </div>
                <ButtonContactUsMobile url={contactUsUrl}/>
            </header>
        </>
    );
};

const MobileMenuItem = ({title, url, onClick, active = false}) => (
    <Link to={url} onClick={() => onClick(url)} className={`${active ? 'text-title' : ''}`}>
        <div aria-hidden="true" className={`flex items-center h-[50px]`}>{title}</div>
    </Link>
);
