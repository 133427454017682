import React from 'react';
import {IoIosPeople, IoIosMail, IoIosPin} from "react-icons/io";

export const Footer = ({contactUsUrl}) => {
    return (
        <footer
            className='pt-10 bg-gradient-to-b from-gray-100/5 to-primary-300/30 dark:bg-gradient-to-b dark:from-gray-900 dark:to-primary-300/30 text-center'>
            <div className='pb-10 px-4 flex flex-col items-center space-y-5'>
                <div className='logo-with-text-sm dark:logo-with-text-white-sm h-[40px] w-[119px]'/>
                <div className='flex flex-col items-center space-y-1'>
                    <a className='text-link hover:text-link/70 dark:text-link-dark dark:hover:text-link-dark/70' href={contactUsUrl} target='_blank' rel="noreferrer">
                        <IoIosPeople className='inline' style={{fontSize: 24}}/>
                        <span className='ml-[5px] cursor-pointer'>Schedule a meeting with us</span>
                    </a>
                    <div>
                        <IoIosMail className='text-gray-400 inline' style={{fontSize: 20}}/>
                        <span className='ml-[6px]'>lam@leansoft-inc.com (Mr. Lam)</span>
                    </div>
                    <div>
                        <IoIosPin className='text-gray-400 inline mt-[-4px]' style={{fontSize: 20}}/>
                        <span
                            className='ml-[2px]'>181 Doan Ngoc Nhac Street, Cam Le District, Da Nang City, Viet Nam.</span>
                    </div>
                </div>
            </div>

            <div className='container mx-auto h-[0.5px] w-full bg-gray-900/5 dark:bg-gray-100/5'/>

            <div className='py-3 px-4 text-sm text-gray-500 dark:text-gray-400'>Copyright &#169; 2018-2024 Leansoft. All
                right reserved.
            </div>
        </footer>
    );
};
